<template>
  <v-container class="pt-0">
    <back-toolbar title="menu.mybookings"></back-toolbar>
    <item-list
      v-if="futureBookings && futureBookings.length > 0"
      :items="futureBookings"
      :showSegmentedControl="false"
    ></item-list>
    <error-image
      v-else
      :text="$t('myPoco.noBookings')"
      imageName="noFavorites.png"
    ></error-image>
    <v-dialog
      v-model="errorDialog"
      max-width="325"
      :timeout="-1"
      overlay-color="backgroundoverlay"
      overlay-opacity="1"
    >
      <v-card
        style="border-radius: 20px !important"
        flat
        class="pa-0"
        v-if="$store.state.reserveout.error"
      >
        <v-toolbar flat dense>
          <v-spacer></v-spacer>
          <v-btn icon @click="errorDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="Text-Dark-Text-1---18-Center mt-4">{{
          $store.state.reserveout.error
        }}</v-card-text>
        <v-container class="text-center">
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="errorDialog = false"
                min-height="48"
                min-width="150"
                class="gradient white--text Text-Dark-Text-1---18-Center"
                >{{ $t("profile.ok") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ItemList from "@/components/recommendations/ItemList";
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
import { mapGetters } from "vuex";
export default {
  components: {
    BackToolbar,
    ItemList,
    ErrorImage,
  },
  computed: {
    errorDialog: {
      get() {
        return this.$store.state.reserveout.status === "failure";
      },
      set(val) {
        this.$store.commit("reserveout/RESET_ERROR");
      },
    },
    ...mapGetters({ futureBookings: "profile/futureBookings" }),
  },
};
</script>
